<template>
	<div class="form-selection">
		<div
			class="dropzone"
			@drop.stop.prevent="dropFiles"
			@dragleave.stop.prevent="send('DRAG_LEAVE')"
			@dragover.stop.prevent="send('DRAG_OVER')"
		>
			<div class="dropzone-text" v-if="state.matches('idle') || state.matches('dragging')">
				<ion-icon :data-state="state.value" name="cloud-upload-outline"></ion-icon>
				<div :data-state="state.value">Drag and drop or</div>
				<button :data-state="state.value" class="ms-button" @click="attachFiles">
					Select Form
				</button>
			</div>

			<div class="error" v-else-if="state.matches('error')">
				<div v-if="state.matches('error.noFilesFound')">No files selected.</div>
				<div v-if="state.matches('error.tooManyFiles')">You may only select one file.</div>
				<div v-if="state.matches('error.invalidMIMEType')">Only PDF files are supported.</div>
				<button class="ms-button" @click="send('CONFIRM_ERROR')">OK</button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { PayloadSender, State } from 'xstate'
import { Context, Event, Schema } from '../types'
import { useFormSelection } from '../hooks'

export default defineComponent({
	name: 'FormSelection',
	props: {
		state: Object as PropType<State<Context, Event, Schema>>,
		send: { type: Function as PropType<PayloadSender<Event>>, required: true },
	},
	setup(props) {
		const { attachFiles, dropFiles } = useFormSelection(props.send)

		return {
			attachFiles,
			dropFiles,
		}
	},
})
</script>

<style lang="scss" scoped>
.form-selection {
	height: 100%;
}

.dropzone {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	border-radius: 8px;
	border: 2px solid #dddddd;
}

.dropzone-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	color: #bebebe;

	ion-icon {
		font-size: 64px;
		display: block;
		transition: all 300ms ease-out;
	}

	ion-icon[data-state='dragging'] {
		transform: translateY(40px);
	}

	div {
		margin: 8px;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 500;
		color: #bebebe;
		transition: all 300ms ease-out;
	}

	div[data-state='dragging'] {
		opacity: 0;
		transform: translateY(4px);
	}

	button {
		transition: all 300ms ease-out;
	}

	button[data-state='dragging'] {
		opacity: 0;
		transform: translateY(4px);
	}
}

.error {
	display: flex;
	flex-direction: column;
	text-align: center;
	color: #bebebe;

	div {
		margin: 8px;
	}
}
</style>
