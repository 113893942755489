<template>
	<div class="form-upload">
		<template v-if="state.matches('idle')">
			<div>Give this form a name:</div>
			<input
				type="text"
				id="form-name"
				v-model.trim="formName"
				@keydown.enter="upload"
				placeholder="The form name cannot be empty"
			/>
			<div class="importFields">
				<input
					type="checkbox"
					id="auto-discover-fields"
					name="auto-discover-fields"
					v-model="importFields"
				/>
				<label for="auto-discover-fields">Import existing fields</label>
			</div>
			<button
				id="uploadBtn"
				class="ms-button"
				@click="upload"
				:disabled="formName.length === 0 ? '' : disabled"
			>
				Upload
			</button>
		</template>

		<template v-else-if="state.matches('failure')">
			<div class="error">
				<div v-if="state.context.result.value.left.context.message.includes('409')">
					<div>Form name in use. Please enter a different form name.</div>
					<br />
					<button class="ms-button" @click="send('RESET')">Rename form</button>
				</div>
				<div v-else>
					<div v-if="state.matches('failure.networkError')">A network error occurred.</div>
					<div v-if="state.matches('failure.validationError')">
						Unexpected response from server.
					</div>
					<br />
					<button class="ms-button" @click="send('RETRY')">Retry</button>
				</div>
			</div>
		</template>

		<template v-else>
			<div v-if="uploadProgress !== 1.0">
				<div>Uploading {{ formName }}</div>
				<br />
				<div>{{ Math.round(uploadProgress * 100) }}%</div>
			</div>
			<div v-else>
				<ion-icon :data-state="state.value" name="checkmark-outline"></ion-icon>
				<div class="success-message" :data-state="state.value">Success!</div>
				<br />
				<button @click="reset" class="ms-button reset-prompt" :data-state="state.value">
					Upload Another Form
				</button>
				<div class="processing-message" :data-state="state.value">
					<progress-dots message="Processing" />
				</div>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, PropType } from 'vue'
import { PayloadSender, State } from 'xstate'
import { Context, Event, Schema } from '@/modules/http/types'
import { useFormUpload } from '../hooks'
import ProgressDots from '@/components/ProgressDots'
import { useFormsStore } from '@/modules/forms'

export default defineComponent({
	name: 'FormUpload',
	components: {
		[ProgressDots.name]: ProgressDots,
	},
	props: {
		state: { type: Object as PropType<State<Context, Event, Schema>>, required: true },
		send: { type: Function as PropType<PayloadSender<Event>>, required: true },
		form: { type: File as PropType<File>, required: true },
	},
	setup(props, context) {
		const store = useFormsStore()
		const formName = ref(props.form.name)
		const formNameProp = computed(() => props.form.name)
		const importFields = ref(true)

		const state = computed(() => props.state)
		const { uploadForm, uploadProgress } = useFormUpload(state, props.send)
		const upload = () => uploadForm(formName.value, props.form, importFields.value)
		const reset = () => props.state.matches('success') && context.emit('reset')

		const disabled = ref(false)

		watch([formNameProp], ([name]) => (formName.value = name))

		watch(state, (state: any) => {
			if (state.value === 'loading') {
				store.state.upLoading = true
			} else {
				store.state.upLoading = false
			}
		})

		return {
			formName,
			importFields,
			upload,
			uploadProgress,
			reset,
			disabled,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.form-upload,
.upload {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

ion-icon {
	margin: auto;
	font-size: 64px;
	display: block;
	opacity: 0;
	transform: translateY(-20px);
	transition: all 300ms ease-out;

	&[data-state='success'] {
		transform: translateY(0px);
		opacity: 1;
	}
}

.success-message {
	opacity: 0;
	transition: all 300ms ease-out;

	&[data-state='success'] {
		opacity: 1;
	}
}

.processing-message {
	margin-top: -50px;
	opacity: 1;
	transform: translateY(-40px);
	transition: all 300ms ease-out;

	&[data-state='success'] {
		opacity: 0;
	}
}

.reset-prompt {
	margin-top: 40px;
	opacity: 0;
	transition: all 300ms ease-out;
	cursor: default;

	&[data-state='success'] {
		cursor: pointer;
		opacity: 1;
	}
}

input[type='text'] {
	margin: 16px;
	width: 500px;
	font-size: 20px;
	max-width: 100%;
	padding: 0 5px;
}

input:placeholder-shown {
	outline: none;
	border: 2px solid $myndshft-orange-400;
}

input::placeholder {
	font-size: smaller;
}

.importFields {
	margin-bottom: 16px;

	& input {
		margin-right: 10px;
	}
}
</style>
