import { AxiosResponse } from 'axios'
import { computed, Ref, ref, watch } from 'vue'
import { useMyndmapApi } from '@/modules/api'
import { useConfigStore } from '@/modules/mynd-config'
import { FormMetadata } from '@/models/form/metadata'
import { requestTask } from '@/modules/http'

import { flow } from 'fp-ts/lib/function'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import * as E from 'fp-ts/lib/Either'
import * as TE from 'fp-ts/lib/TaskEither'

/**
 * Computes the list of preview images for the selected form.
 */
export function useFormImagePreviews(selectedForm: Ref<O.Option<FormMetadata>>) {
	const configStore = useConfigStore()
	const config = computed(() => configStore.getters['getConfig'])
	const { getFormPageEndpoint } = useMyndmapApi(config)

	const fetchPreviewImages = ref<TE.TaskEither<any, string>[]>([])

	watch([selectedForm], ([selectedForm]) => {
		fetchPreviewImages.value = pipe(
			selectedForm,
			O.map((form) => {
				return pipe(
					A.range(0, form.pageCount - 1),
					A.map((i) => {
						if (form.id) {
							return getFormPageEndpoint(form.id)(i)
						} else {
							return E.left({ type: 'BLOB_NOT_FOUND' })
						}
					}),
					A.map(
						flow(
							TE.fromEither,
							TE.chainW((url) => requestTask(url, { responseType: 'blob' })),
							TE.chainW((response) => {
								return response.data
									? TE.right((response as AxiosResponse<Blob>).data)
									: TE.left({ type: 'BLOB_NOT_FOUND' })
							}),
							TE.map((blob) => URL.createObjectURL(blob))
						)
					)
				)
			}),
			O.getOrElseW(() => [])
		)
	})

	return { fetchPreviewImages }
}
