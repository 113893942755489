
import { defineComponent } from 'vue'
import { useProgressDots } from './service'

export default defineComponent({
	name: 'ProgressDots',
	props: {
		message: String,
		period: {
			type: Number,
			default: 1000, // milliseconds
		},
		max: {
			type: Number,
			default: 3,
		},
		char: {
			type: String,
			default: '.',
		},
	},
	setup(props) {
		return useProgressDots(props.period, props.max, props.char)
	},
})
