
import { SetupContext, defineComponent, ref, watch, onBeforeUpdate, PropType } from 'vue'
import * as E from 'fp-ts/Either'
import * as TE from 'fp-ts/TaskEither'

export default defineComponent({
	name: 'FormInformation',
	props: {
		pages: {
			type: Array as PropType<Array<TE.TaskEither<any, string>>>,
			default: () => [],
		},
	},
	setup(props, context: SetupContext) {
		const container = ref<HTMLElement | null>(null)
		const images = ref<HTMLImageElement[]>([])
		let hasIntersected: { [index: number]: boolean } = {}

		onBeforeUpdate(() => {
			images.value = []
		})

		watch(
			() => images.value,
			(images) => {
				hasIntersected = {}

				const observer = new IntersectionObserver(
					(entries) => {
						entries.forEach((entry) => {
							if (entry.isIntersecting) {
								const imageElement = entry.target as HTMLImageElement
								const pageIndex = images.findIndex((image) => image === imageElement)
								const fetchPage = props.pages[pageIndex]

								if (!hasIntersected[pageIndex]) {
									fetchPage().then((result) => {
										if (E.isRight(result)) {
											imageElement.src = result.right
											imageElement.className = 'page'
											hasIntersected[pageIndex] = true
										}
									})
								}
							}
						})
					},
					{
						root: container.value,
					}
				)

				images.forEach((image) => {
					observer.observe(image)
				})
			}
		)

		const closeHandler = () => {
			context.emit('close')
		}

		const clickHandler = (event: MouseEvent & { src: string }, src: string) => {
			event.src = src
			context.emit('click', event)
		}

		watch(
			() => [props.pages],
			() => {
				container.value?.scroll(0, 0)
			}
		)

		return {
			container,
			images,
			closeHandler,
			clickHandler,
		}
	},
})
