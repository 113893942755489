<template>
	<div ref="container" class="information-container">
		<div class="header">
			<span>Preview</span>
			<ion-icon @click="closeHandler" class="close" name="close"></ion-icon>
		</div>
		<div class="pages">
			<img
				class="hide"
				v-for="(page, index) of pages"
				:ref="
					(image) => {
						if (image) images[index] = image
					}
				"
				loading.native="lazy"
				@click="clickHandler($event, page)"
				:key="index"
				:alt="`Page ${index + 1}`"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import { SetupContext, defineComponent, ref, watch, onBeforeUpdate, PropType } from 'vue'
import * as E from 'fp-ts/Either'
import * as TE from 'fp-ts/TaskEither'

export default defineComponent({
	name: 'FormInformation',
	props: {
		pages: {
			type: Array as PropType<Array<TE.TaskEither<any, string>>>,
			default: () => [],
		},
	},
	setup(props, context: SetupContext) {
		const container = ref<HTMLElement | null>(null)
		const images = ref<HTMLImageElement[]>([])
		let hasIntersected: { [index: number]: boolean } = {}

		onBeforeUpdate(() => {
			images.value = []
		})

		watch(
			() => images.value,
			(images) => {
				hasIntersected = {}

				const observer = new IntersectionObserver(
					(entries) => {
						entries.forEach((entry) => {
							if (entry.isIntersecting) {
								const imageElement = entry.target as HTMLImageElement
								const pageIndex = images.findIndex((image) => image === imageElement)
								const fetchPage = props.pages[pageIndex]

								if (!hasIntersected[pageIndex]) {
									fetchPage().then((result) => {
										if (E.isRight(result)) {
											imageElement.src = result.right
											imageElement.className = 'page'
											hasIntersected[pageIndex] = true
										}
									})
								}
							}
						})
					},
					{
						root: container.value,
					}
				)

				images.forEach((image) => {
					observer.observe(image)
				})
			}
		)

		const closeHandler = () => {
			context.emit('close')
		}

		const clickHandler = (event: MouseEvent & { src: string }, src: string) => {
			event.src = src
			context.emit('click', event)
		}

		watch(
			() => [props.pages],
			() => {
				container.value?.scroll(0, 0)
			}
		)

		return {
			container,
			images,
			closeHandler,
			clickHandler,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.information-container {
	height: calc(100vh - 40px);
	background: $myndshft-gray-900;
	transition: width 100ms ease-out;
	overflow-y: scroll;
}

.header {
	position: sticky;
	padding: 16px;
	top: 0px;
	background: $myndshft-gray-900;
	border-bottom: 1px solid $myndshft-gray-800;
	font-size: 16px;
	display: flex;
	justify-content: space-between;
}

.close {
	padding: 4px;
	cursor: pointer;
}

.page {
	cursor: pointer;
	padding: 0 16px;
	margin: 16px 0;
	width: 100%;
}

.hide {
	visibility: hidden;
}
</style>
