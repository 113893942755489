<template>
	<div class="form-upload-flow">
		<template v-if="!selectState.matches('validated')">
			<form-selection :state="selectState" :send="selectSend" />
		</template>
		<template v-else-if="form != null">
			<form-upload @reset="reset" :state="uploadState" :send="uploadSend" :form="form" />
		</template>
	</div>
</template>

<script lang="ts">
import { SetupContext, defineComponent, ref, watch, onBeforeMount, computed } from 'vue'
import { createRequestMachine } from '@/modules/http'
import { useConfigStore } from '@/modules/mynd-config'
import { FormUploadResponse } from '@/models/form/response'
import { dragAndDropService } from './machine'
import { useMachine, useService } from '@xstate/vue'

import FormSelection from './states/FormSelection.vue'
import FormUpload from './states/FormUpload.vue'

function createFormUploadMachine(apiServer: string) {
	const uploadMachine = createRequestMachine(`${apiServer}/forms`, FormUploadResponse, {
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})

	return uploadMachine
}

export default defineComponent({
	name: 'FormUploadFlow',
	components: {
		[FormSelection.name]: FormSelection,
		[FormUpload.name]: FormUpload,
	},
	setup(_, context: SetupContext) {
		const configStore = useConfigStore()
		const config = computed(() => configStore.getters['getConfig'])
		const form = ref<File | null>(null)

		const { state: selectState, send: selectSend } = useService(dragAndDropService)
		const { state: uploadState, send: uploadSend } = useMachine(
			createFormUploadMachine(config.value.apiServer)
		)

		onBeforeMount(() => {
			if (selectState.value.matches('validated')) {
				form.value = selectState.value.context.files[0]
			}

			if (uploadState.value.matches('success')) {
				context.emit('reload')
			}
		})

		watch(
			() => selectState.value,
			(state) => {
				if (state.matches('validated')) {
					form.value = selectState.value.context.files[0]
				}
			}
		)

		watch([uploadState], ([state]) => {
			if (state.matches('success')) {
				context.emit('reload')
			}
		})

		const reset = () => {
			selectSend('RESET')
			uploadSend('RESET')
		}

		return {
			form,
			selectState,
			selectSend,
			uploadState,
			uploadSend,
			reset,
		}
	},
})
</script>

<style lang="scss" scoped>
.form-upload-flow {
	height: 100%;
	padding: 40px;
}
</style>
