import { computed, ref, watch } from 'vue'
import { toast } from '@/components/Toast'

import { ServerForm, formIso } from '@/models/form'
import { FormMetadata, extractFormMetadata } from '@/models/form/metadata'
import { RequestState, unwrapData } from '@/modules/http'
import { useConfigStore } from '@/modules/mynd-config'
import { useMyndmapApi } from '@/modules/api'
import { useMachine } from '@xstate/vue'
import { useFormsStore } from '@/modules/forms'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'

/**
 * Fetch the list of form metadata to display in the table.
 */
export function useFormMetadataList() {
	const store = useFormsStore()
	const configStore = useConfigStore()
	const config = computed(() => configStore.getters['getConfig'])
	const formMetadataList = ref<FormMetadata[]>([])
	const { getFormListMachine } = useMyndmapApi(config)
	const requestState = ref<O.Option<RequestState<ServerForm[]>>>(O.none)
	const machine = pipe(getFormListMachine(), E.map(useMachine))

	const isLoading = computed(() =>
		pipe(
			requestState.value,
			O.fold(
				() => true,
				(state) => state.matches('loading')
			)
		)
	)

	pipe(
		machine,
		E.fold(
			() => toast('Failed to read Mynd Config'),
			({ state, send }) => {
				send('DISPATCH')
				watch([state], ([state]) => {
					requestState.value = O.some(state)
				})
			}
		)
	)

	watch([requestState], ([requestState]) => {
		if (O.isSome(requestState) && requestState.value.matches('success')) {
			formMetadataList.value = pipe(
				unwrapData(requestState.value.context.result),
				O.map(A.map(formIso.get)),
				O.map(A.map(extractFormMetadata)),
				O.getOrElse(() => [] as FormMetadata[])
			)
		}
	})

	const reloadFormMetadataList = () =>
		pipe(
			machine,
			E.map(({ send }) => {
				send('RESET')
				send('DISPATCH')
			})
		)

	watch(formMetadataList, (formMetadataList) => {
		store.commit('setForms', formMetadataList)
	})

	return {
		isLoading,
		formMetadataList,
		reloadFormMetadataList,
	}
}
