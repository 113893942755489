
import { SetupContext, defineComponent } from 'vue'
import { useService } from '@xstate/vue'
import { dragAndDropService } from '../machine'
import { useFormSelection } from '../hooks'

export default defineComponent({
	name: 'DropContainer',
	setup(_, context: SetupContext) {
		const { send: selectSend } = useService(dragAndDropService)
		const { dropFiles } = useFormSelection(selectSend)

		const listFileDrop = (e: DragEvent) => {
			dropFiles(e)
			context.emit('listFileDrop', e)
		}

		return {
			selectSend,
			listFileDrop,
		}
	},
})
