<template>
	<div ref="container" :style="{ gridTemplateColumns: templateStyle }" class="split-panes">
		<div class="left" :style="{ height }">
			<slot name="left"></slot>
		</div>
		<div ref="leftHandle" class="gutter"></div>
		<div class="main" :style="{ height }">
			<slot></slot>
		</div>
		<div ref="rightHandle" class="gutter"></div>
		<div class="right" :style="{ height }">
			<slot name="right"></slot>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, onMounted, computed } from 'vue'
import { useFormsStore } from '@/modules/forms'
import Split from 'split-grid'

import { pipe } from 'fp-ts/lib/function'
import * as NEA from 'fp-ts/lib/NonEmptyArray'
import * as O from 'fp-ts/lib/Option'

type SidePaneOptions = {
	disabledByDefault: boolean
	minSize: number
	defaultSize: string
	resizable: boolean
}

type MainPaneOptions = {
	minSize: number
	defaultSize: string
}

interface TemplateColumns {
	leftPanel?: string
	leftGutter?: string
	mainContent?: string
	rightGutter?: string
	rightPanel?: string
}

function parseTemplateColumns(template: string): TemplateColumns {
	const [leftPanel, leftGutter, mainContent, rightGutter, rightPanel] = template.split(' ')
	return { leftPanel, leftGutter, mainContent, rightGutter, rightPanel }
}

function stringifyTemplateColumns(template: TemplateColumns): string {
	return [
		template.leftPanel ?? '0',
		template.leftGutter ?? '0',
		template.mainContent ?? '0',
		template.rightGutter ?? '0',
		template.rightPanel ?? '0',
	].join(' ')
}

function mergeTemplateColumns(...templates: (TemplateColumns | string)[]): TemplateColumns {
	// Convert string representations
	const templateColumns = templates.map((t) =>
		typeof t === 'string' ? parseTemplateColumns(t) : t
	)

	// Merge two template columns together
	const merge = (t1: TemplateColumns, t2: TemplateColumns) => ({ ...t1, ...t2 })

	return pipe(
		templateColumns,
		NEA.fromArray,
		O.map(NEA.reduce(templateColumns[0], merge)),
		O.getOrElse(() => ({}))
	)
}

function projectLeftColumn(template: TemplateColumns | string): TemplateColumns {
	const templateColumns = typeof template === 'string' ? parseTemplateColumns(template) : template
	return {
		leftPanel: templateColumns.leftPanel,
		leftGutter: templateColumns.leftGutter,
	}
}

function projectRightColumn(template: TemplateColumns | string): TemplateColumns {
	const templateColumns = typeof template === 'string' ? parseTemplateColumns(template) : template
	return {
		rightPanel: templateColumns.rightPanel,
		rightGutter: templateColumns.rightGutter,
	}
}

const disabledLeftColumn = {
	leftPanel: '0',
	leftGutter: '0',
}

const disabledRightColumn = {
	rightPanel: '0',
	rightGutter: '0',
}

const gutterWidth = '8px'

export default defineComponent({
	name: 'SplitPanes',
	props: {
		height: {
			type: String,
			default: '100%',
		},
		left: {
			type: Object as PropType<SidePaneOptions>,
			default: () => ({
				disabledByDefault: false,
				minSize: 0,
				defaultSize: '200px',
				resizable: true,
			}),
		},
		right: {
			type: Object as PropType<SidePaneOptions>,
			default: () => ({
				disabledByDefault: false,
				minSize: 0,
				defaultSize: '200px',
				resizable: true,
			}),
		},
		main: {
			type: Object as PropType<MainPaneOptions>,
			default: () => ({
				minSize: 0,
			}),
		},
	},
	setup(props) {
		const formStore = useFormsStore()
		const container = ref<HTMLElement | null>(null)
		const leftHandle = ref<HTMLElement | null>(null)
		const rightHandle = ref<HTMLElement | null>(null)

		const isLeftEnabled = computed(() => formStore.state.isLeftEnabled)
		const isRightEnabled = ref(!props.right.disabledByDefault)

		// Initialize split grid
		const split = ref<typeof Split>(null)

		// Initialize grid template columns
		const templateStyle = ref('')

		const leftConfig = ref<TemplateColumns>({
			leftPanel: props.left.defaultSize,
			leftGutter: gutterWidth,
		})

		const rightConfig = ref<TemplateColumns>({
			rightPanel: props.right.defaultSize,
			rightGutter: gutterWidth,
		})

		onMounted(() => {
			const template: TemplateColumns = {}
			template.mainContent = '1fr'

			if (isLeftEnabled.value) {
				template.leftPanel = props.left.defaultSize
				template.leftGutter = gutterWidth
			}

			if (!props.right.disabledByDefault) {
				template.rightPanel = props.right.defaultSize
				template.rightGutter = gutterWidth
			}

			templateStyle.value = stringifyTemplateColumns(template)

			// (Re-)define the split grid
			split.value = Split({
				columnMinSizes: {
					0: props.left.minSize ?? 0,
					2: props.main.minSize ?? 0,
					4: props.right.minSize ?? 0,
				},

				columnGutters: [
					{
						track: 1,
						element: leftHandle.value,
					},
					{
						track: 3,
						element: rightHandle.value,
					},
				],
			})
		})

		const toggleLeftPanel = () => {
			if (container.value !== null) {
				const currentConfig = container.value.style.gridTemplateColumns
				if (isLeftEnabled.value) {
					formStore.commit('setIsLeftEnabled', false)
					leftConfig.value = pipe(currentConfig, parseTemplateColumns, projectLeftColumn)
					templateStyle.value = pipe(
						mergeTemplateColumns(currentConfig, disabledLeftColumn),
						stringifyTemplateColumns
					)
				} else {
					formStore.commit('setIsLeftEnabled', true)
					templateStyle.value = pipe(
						mergeTemplateColumns(currentConfig, leftConfig.value),
						stringifyTemplateColumns
					)
				}
			}
		}

		const toggleRightPanel = () => {
			if (container.value !== null) {
				const currentConfig = container.value.style.gridTemplateColumns
				if (isRightEnabled.value) {
					isRightEnabled.value = false
					rightConfig.value = pipe(currentConfig, parseTemplateColumns, projectRightColumn)
					templateStyle.value = pipe(
						mergeTemplateColumns(currentConfig, disabledRightColumn),
						stringifyTemplateColumns
					)
				} else {
					isRightEnabled.value = true
					templateStyle.value = pipe(
						mergeTemplateColumns(currentConfig, rightConfig.value),
						stringifyTemplateColumns
					)
				}
			}
		}

		return {
			// Template Refs
			container,
			leftHandle,
			rightHandle,

			// API
			toggleLeftPanel,
			toggleRightPanel,
			isLeftEnabled,
			isRightEnabled,

			// Styles
			templateStyle,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.split-panes {
	display: grid;
}

.gutter {
	background: linear-gradient(90deg, rgba(0, 0, 0, 0) 50%, $myndshft-gray-900 50%);
	cursor: col-resize;
}
</style>
