import { computed } from 'vue'
import { useMachine } from '@xstate/vue'
import { progressDotsMachine } from './machine'

export function useProgressDots(period: number, max: number, char: string) {
	const { state, send } = useMachine(progressDotsMachine)

	const dots = computed(() => char.repeat(state.value.context.count))

	const begin = () => {
		send({
			type: 'BEGIN',
			max: max,
			period: period,
		})
	}

	const end = () => {
		send({ type: 'END' })
	}

	return {
		dots,
		begin,
		end,
	}
}
