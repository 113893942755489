export default {
	// viewport height - toolbar height
	height: 'calc(100vh - 40px)',
	left: {
		disabledByDefault: true,
		minSize: 400,
		defaultSize: '400px',
		resizable: false,
	},
	right: {
		disabledByDefault: true,
		minSize: 200,
		defaultSize: '400px',
		resizable: true,
	},
	main: {
		minSize: 800,
	},
}
