<template>
	<div class="loading" v-if="loading">Loading...</div>
	<div v-else>
		<spacer size="16" />
		<div v-for="row of data" :key="row.id">
			<div
				class="row"
				@click="selectedRow = row"
				@dblclick="doubleClickRow(row)"
				:data-active="selectedRow && selectedRow.id === row.id"
				:style="[{ background: undefined }]"
			>
				<div class="cell" v-for="(column, index) of columns" :field="column.field" :key="index">
					<div v-if="column.field === 'formType'">
						{{ getFormTypeLabel(row[column.field]) }}
					</div>
					<div v-else-if="column.field === 'name'" class="form-name">
						{{ row[column.field] }}
					</div>
					<div v-else-if="column.field === 'formStatus'">
						{{ getFormStatusLabel(row[column.field]) }}
					</div>
					<div v-else-if="column.field === 'lastModified'">
						{{ row[column.field].display }}
					</div>
					<div v-else-if="column.field !== 'actions'">
						{{ row[column.field] }}
					</div>
					<div v-else-if="!!selectedRow && selectedRow.id === row.id" @dblclick.stop="">
						<div id="formListActions" class="formListActions">
							<div id="formListItemCopy" class="copy-icon copy-icon-hover" @click="copyForm(row)">
								<font-awesome-icon icon="copy" />
							</div>
							<div id="formListItemEdit" class="edit-icon" @click="showEditModal">
								<font-awesome-icon icon="pen-square" />
							</div>
							<div
								id="formListItemDelete"
								class="delete-icon delete-icon-hover"
								@click="deleteForm(row)"
							>
								<font-awesome-icon icon="trash" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<spacer size="16" />
		<modal ref="editModal" :max-width="800"
			><form-detail-editor
				:details="details"
				:form-type-options="formTypeOptions"
				:saving="saving"
				@save="updateFormDetails(selectedRow?.id, $event)"
				@close="hideEditModal"
		/></modal>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, SetupContext, watch } from 'vue'
import { FormMetadata } from '@/models/form/metadata'
import { useMyndmapApi } from '@/modules/api/hook'
import { useConfigStore } from '@/modules/mynd-config'

import Spacer from '@/components/Spacer'
import { toast } from '@/components/Toast'

import * as E from 'fp-ts/lib/Either'
import { UUID } from 'io-ts-types/lib/UUID'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { FORM_STATUS, FormDetails, FormType } from '@/models/form/form'
import { getApiErrorMessage } from '@/modules/api'
import { useFormMetadataList } from '@/pages/Files/hooks/form-metadata-list'
import Modal from '@/components/Modal/Modal.vue'
import { constVoid } from 'fp-ts/function'
import FormDetailEditor from '@/components/FormDetailEditor'

export default defineComponent({
	name: 'FormListBody',
	components: {
		Modal,
		Spacer,
		'font-awesome-icon': FontAwesomeIcon,
		FormDetailEditor,
	},
	props: {
		loading: Boolean,
		columns: {
			type: Array,
			default: () => [],
		},
		data: {
			type: Array,
			default: () => [],
		},
	},
	setup(props, context: SetupContext) {
		const selectedRow = ref<FormMetadata | null>(null)
		const configStore = useConfigStore()
		const config = computed(() => configStore.getters['getConfig'])
		const { reloadFormMetadataList: reloadList } = useFormMetadataList()
		const { updateFormDetails: updateFormDetailsRequest } = useMyndmapApi(config)

		const formId = ref<string>('')
		const saving = ref<boolean>(false)
		const editModal = ref<InstanceType<typeof Modal> | null>(null)

		const showEditModal = computed(() => editModal.value?.showHandler ?? constVoid)
		const hideEditModal = computed(() => editModal.value?.hideHandler ?? constVoid)

		const formTypeOptions: { id: string; value: string }[] = [
			{ id: FormType.PORTAL, value: 'Portal' },
			{ id: FormType.FAX, value: 'Fax' },
			{ id: FormType.MANUAL, value: 'Manual' },
		]

		const details = ref({
			name: selectedRow.value?.name,
			type: selectedRow.value?.formType,
			status: selectedRow.value?.formStatus,
		})

		const getFormTypeLabel = (type: string) =>
			formTypeOptions.find((formTypeOption) => (formTypeOption.id as string) === type)?.value

		const getFormStatusLabel = (status: string | null) => {
			let label = ''
			if (status) {
				label = status.toLowerCase()
				label = `${label[0].toUpperCase()}${label.slice(1)}`
			}
			return label
		}
		const reloadIcon = () => {
			reloadList()
		}

		//Selected Row
		watch([selectedRow], ([selectedRow]) => {
			context.emit('select', selectedRow)
			if (selectedRow?.id !== formId.value) {
				formId.value = ''
			}
		})

		const selectedFormId = (selectedFormId: UUID) => {
			const isRow = props.data.find((row: any) => row.id === selectedFormId)
			selectedRow.value = isRow as FormMetadata
		}

		const doubleClickRow = (id?: string) => {
			context.emit('open', id)
		}

		//Edit
		enum EditState {
			INACTIVE,
			SAVING,
		}

		const editState = ref<EditState>(EditState.INACTIVE)

		const copyForm = (row: any) => {
			const newName = window.prompt('Enter new form name', `${row.name} - COPY`)

			if (newName === null) {
				return
			} else if (newName.trim() === '') {
				toast(`Please enter a valid name`)
			} else {
				toast(`Copying form ${row.name} to ${newName}`)
				useMyndmapApi(config)
					.copyForm(row, newName.trim())()
					.then(
						E.fold(
							(error) => toast(getApiErrorMessage(error)),
							() => {
								context.emit('reloadList')
								toast(`Successfully copied ${row.name}`)
							}
						)
					)
			}
		}

		const deleteForm = (row: any) => {
			if (
				window.confirm(
					`Are you sure you want to delete: ${row.name}? This action cannot be undone.`
				)
			) {
				useMyndmapApi(config)
					.deleteForm(row.id)()
					.then(
						E.fold(
							(error) => toast(getApiErrorMessage(error)),
							() => {
								context.emit('reloadList')
								toast(`Successfully deleted ${row.name}`)
							}
						)
					)
			}
		}

		const updateFormDetails = (id: UUID, formDetails: FormDetails) => {
			saving.value = true
			editState.value = EditState.SAVING
			const detailsToSave: Record<string, any> = {}
			if (selectedRow.value?.['name'] !== formDetails.name) {
				detailsToSave['name'] = {
					newValue: formDetails.name,
					oldValue: selectedRow.value?.['name'],
				}
			}
			if (selectedRow.value?.['formType'] !== formDetails.type) {
				detailsToSave['form_type'] = {
					newValue: formDetails.type || FormType.MANUAL,
					oldValue: selectedRow.value?.['formType'] || FormType.MANUAL,
				}
			}
			if (selectedRow.value?.['formStatus'] !== formDetails.status) {
				detailsToSave['form_status'] = {
					newValue: formDetails.status || FORM_STATUS.DRAFT,
					oldValue: selectedRow.value?.['formStatus'] || FORM_STATUS.DRAFT,
				}
			}
			if (!Object.keys(detailsToSave).length) {
				hideEditModal?.value?.()
				saving.value = false
				editState.value = EditState.INACTIVE
			}

			if (Object.values(detailsToSave).some((detail) => !!Object.keys(detail).length)) {
				const keys = Object.keys(detailsToSave)

				if (selectedRow.value) {
					if (keys.includes('name')) {
						selectedRow.value.name = detailsToSave.name.newValue
					}
					if (keys.includes('form_type')) {
						selectedRow.value.formType = detailsToSave.form_type.newValue
					}
					if (keys.includes('form_status')) {
						selectedRow.value.formStatus = detailsToSave.form_status.newValue
					}
				}

				saveFormDetails(id, detailsToSave).then(
					E.fold(
						(error) => {
							editState.value = EditState.INACTIVE
							if (selectedRow.value) {
								if (keys.includes('name')) {
									selectedRow.value.name = detailsToSave.name.oldValue
								}
								if (keys.includes('form_type')) {
									selectedRow.value.formType = detailsToSave.form_type.oldValue
								}
								if (keys.includes('form_status')) {
									selectedRow.value.formStatus = detailsToSave.form_status.oldValue
								}
							}
							saving.value = false
							toast(getApiErrorMessage(error))
						},
						(_) => {
							editState.value = EditState.INACTIVE
							saving.value = false
							hideEditModal?.value?.()
						}
					)
				)
			}
		}

		const saveFormDetails = async (id: UUID, details: Record<string, any>) => {
			const executeRequest = updateFormDetailsRequest(id)(details)
			return await executeRequest()
		}

		watch(
			[
				() => selectedRow.value?.name,
				() => selectedRow.value?.formType,
				() => selectedRow.value?.formStatus,
			],
			([name, type, status]) => {
				details.value = { name, type, status }
			}
		)

		return {
			selectedRow,
			formId,
			editState,
			EditState,
			saving,
			editModal,
			showEditModal,
			hideEditModal,
			formTypeOptions,
			details,

			doubleClickRow,
			reloadList,
			reloadIcon,
			selectedFormId,
			copyForm,
			deleteForm,
			updateFormDetails,
			getFormTypeLabel,
			getFormStatusLabel,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '../styles.scss';
@import '@/styles/colors.scss';

.cell {
	@extend .cell;
	&[field='procedureCodes'] {
		overflow: visible !important;
	}
}

.collapsible {
	background: whitesmoke;
	user-select: none;
	display: grid;
	grid-template-columns: 80fr;
	padding: 0 5px 0 10px;
	color: #525252;
	transition: background 200ms ease-out;
	border-radius: 8px;
}

.form-name {
	padding-right: 10px;
}

.codes-column {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
}

.expandUnicode:hover {
	cursor: pointer;
}

.tooltip-icon {
	color: $myndshft-orange-100;
}

.tooltip {
	position: relative;
	font-size: large;
	padding: 0 2px;
}

.tooltip-text {
	padding: 0 2px;
	margin: 0 2px;
}

.row:not(.header)[data-active='true'] .tooltip {
	background: $myndshft-orange-300;
	color: white;
}

.tooltip .tooltip-text {
	visibility: hidden;
	background-color: $myndshft-gray-600;
	color: #fff;
	font-size: smaller;
	text-align: center;
	padding: 2px 4px;
	opacity: 0.9;
	position: absolute;
	z-index: 1;
}

.tooltip:hover {
	color: $myndshft-gray-600;
}
.tooltip:hover .tooltip-text {
	visibility: visible;
	overflow: visible;
}
.formListActions {
	display: flex;
	justify-content: flex-start;
}
.formListActions .copy-icon,
.formListActions .delete-icon {
	margin: 0 0.5em;
	width: 1em;
}

.edit-icon:hover,
.copy-icon:hover,
.delete-icon:hover {
	cursor: pointer;
	transform: translateY(-1px);
}

.loading {
	position: absolute;
	margin-top: 30px;
	left: calc(100vw / 2);
	display: flex;
	justify-content: center;
}

.saving {
	display: flex;
	justify-content: center;
}

.icons {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	span {
		padding: 0 10px;
	}
	overflow: visible;
}
</style>
