
import { defineComponent, computed, ref, watch, PropType } from 'vue'
import { PayloadSender, State } from 'xstate'
import { Context, Event, Schema } from '@/modules/http/types'
import { useFormUpload } from '../hooks'
import ProgressDots from '@/components/ProgressDots'
import { useFormsStore } from '@/modules/forms'

export default defineComponent({
	name: 'FormUpload',
	components: {
		[ProgressDots.name]: ProgressDots,
	},
	props: {
		state: { type: Object as PropType<State<Context, Event, Schema>>, required: true },
		send: { type: Function as PropType<PayloadSender<Event>>, required: true },
		form: { type: File as PropType<File>, required: true },
	},
	setup(props, context) {
		const store = useFormsStore()
		const formName = ref(props.form.name)
		const formNameProp = computed(() => props.form.name)
		const importFields = ref(true)

		const state = computed(() => props.state)
		const { uploadForm, uploadProgress } = useFormUpload(state, props.send)
		const upload = () => uploadForm(formName.value, props.form, importFields.value)
		const reset = () => props.state.matches('success') && context.emit('reset')

		const disabled = ref(false)

		watch([formNameProp], ([name]) => (formName.value = name))

		watch(state, (state: any) => {
			if (state.value === 'loading') {
				store.state.upLoading = true
			} else {
				store.state.upLoading = false
			}
		})

		return {
			formName,
			importFields,
			upload,
			uploadProgress,
			reset,
			disabled,
		}
	},
})
