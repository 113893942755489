<template>
	<div class="form-details-editor-container">
		<div class="form-details-container-label">
			<span>Form Details Editor</span>
		</div>
		<div class="form-details-name">
			<label>Form Name:</label>
			<input
				type="text"
				id="form-details-name-input"
				:value="formDetails.name"
				@input="update.name($event.target.value)"
			/>
		</div>
		<div class="form-details-type">
			<label>Form Type:</label>
			<vue-select
				label="Form Type"
				id="form-details-type-select"
				class="type-select"
				@update="update.type"
				:value="formDetails.type"
				:options="formTypeOptions"
				:reducer="(field) => field.id"
			></vue-select>
		</div>
		<div class="form-details-status">
			<label>Form Status:</label>
			<div class="status-text-button">
				<span>{{ isDraft ? 'Draft' : 'Published' }}</span>
				<button class="ms-button ms-slim" @click="updateStatus" :disabled="isStatusUpdateDisabled">
					{{ isDraft ? 'Set to Published' : 'Set to Draft' }}
				</button>
			</div>
		</div>
		<div class="form-details-footer">
			<button
				id="form-details-discard-button"
				class="ms-button ms-slim"
				@click="close"
				:disabled="saving"
			>
				Discard
			</button>
			<button
				id="form-details-save-button"
				class="ms-button ms-slim"
				@click="save"
				:disabled="!isValid || saving"
			>
				{{ !!saving ? 'Saving..' : 'Save' }}
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { FORM_STATUS, FormDetails } from '@/models/form/form'
import VueSelect from '@/components/Select/VueSelect.vue'

export default defineComponent({
	name: 'form-detail-editor',
	components: { VueSelect },
	emits: ['save', 'close'],
	props: {
		details: {
			type: Object,
			required: true,
		},
		formTypeOptions: {
			type: Array,
			default: () => [],
		},
		saving: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context) {
		const isValid = ref(false)
		const formDetails = ref<FormDetails>(props.details as FormDetails)
		const isDraft = computed<boolean>(() => formDetails.value.status === FORM_STATUS.DRAFT)

		// disable status update if published or doesn't have permissions
		const isStatusUpdateDisabled = computed<boolean>(() => !isDraft.value)

		const validateDetails = () => {
			const validateName = () => {
				return !!formDetails.value.name
			}
			const validateType = () => formDetails.value.type !== ''
			isValid.value = validateName() && validateType()
		}

		const update = {
			type: (type: string) => (formDetails.value = { ...formDetails.value, type }),
			status: (status: FORM_STATUS) => (formDetails.value = { ...formDetails.value, status }),
			name: (name: string) => (formDetails.value = { ...formDetails.value, name: name.trim() }),
		}

		const save = () => context.emit('save', formDetails.value)
		const close = () => context.emit('close')

		const updateStatus = () => {
			if (!isStatusUpdateDisabled.value) {
				if (isDraft.value) {
					update.status(FORM_STATUS.PUBLISHED)
				} else {
					update.status(FORM_STATUS.DRAFT)
				}
			}
		}

		watch(formDetails, validateDetails, { immediate: true })
		watch(
			() => props.details,
			(details) => {
				formDetails.value = { ...details } as FormDetails
			}
		)

		return {
			isValid,
			formDetails,
			update,
			isDraft,
			isStatusUpdateDisabled,

			save,
			close,
			updateStatus,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.form-details-editor-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.form-details-container-label {
	order: 1;
	width: 100%;
	text-align: center;
	padding: 10px;
	font-size: 24px;
	border-bottom: 2px solid $myndshft-gray-900;
}

.form-details-name,
.form-details-type,
.form-details-status {
	label {
		width: 160px;
		text-align: right;
	}
	input,
	.type-select {
		width: 100%;
		text-align: left;
		margin: 0 5px;
		padding: 0px;
	}
}

.form-details-name {
	order: 2;
	display: inline-flex;
	column-gap: 10px;
	padding: 10px;
}

.form-details-type {
	order: 3;
	display: inline-flex;
	column-gap: 10px;
	padding: 10px;
}

.form-details-status {
	order: 4;
	display: inline-flex;
	column-gap: 10px;
	padding: 10px;
	.status-text-button {
		display: inline-flex;
		width: 100%;
		justify-content: space-between;
		text-align: left;
		margin: 0 5px;
	}
	span {
		padding: 2px;
		flex-grow: 1;
		border: 1px solid rgba(60, 60, 60, 0.7);
		border-radius: 2px;
	}
	button {
		margin: 0 5px;
	}
}

.form-details-footer {
	order: 6;
	align-self: flex-end;
	flex-grow: 2;
	display: flex;
	flex-direction: row;
	column-gap: 10px;
	padding: 20px;

	button {
		align-self: flex-end;
		width: 90px;
	}
}
</style>
