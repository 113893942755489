
import { defineComponent, PropType } from 'vue'
import { PayloadSender, State } from 'xstate'
import { Context, Event, Schema } from '../types'
import { useFormSelection } from '../hooks'

export default defineComponent({
	name: 'FormSelection',
	props: {
		state: Object as PropType<State<Context, Event, Schema>>,
		send: { type: Function as PropType<PayloadSender<Event>>, required: true },
	},
	setup(props) {
		const { attachFiles, dropFiles } = useFormSelection(props.send)

		return {
			attachFiles,
			dropFiles,
		}
	},
})
