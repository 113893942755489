
import { computed, defineComponent, ref, watch } from 'vue'
import { FORM_STATUS, FormDetails } from '@/models/form/form'
import VueSelect from '@/components/Select/VueSelect.vue'

export default defineComponent({
	name: 'form-detail-editor',
	components: { VueSelect },
	emits: ['save', 'close'],
	props: {
		details: {
			type: Object,
			required: true,
		},
		formTypeOptions: {
			type: Array,
			default: () => [],
		},
		saving: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context) {
		const isValid = ref(false)
		const formDetails = ref<FormDetails>(props.details as FormDetails)
		const isDraft = computed<boolean>(() => formDetails.value.status === FORM_STATUS.DRAFT)

		// disable status update if published or doesn't have permissions
		const isStatusUpdateDisabled = computed<boolean>(() => !isDraft.value)

		const validateDetails = () => {
			const validateName = () => {
				return !!formDetails.value.name
			}
			const validateType = () => formDetails.value.type !== ''
			isValid.value = validateName() && validateType()
		}

		const update = {
			type: (type: string) => (formDetails.value = { ...formDetails.value, type }),
			status: (status: FORM_STATUS) => (formDetails.value = { ...formDetails.value, status }),
			name: (name: string) => (formDetails.value = { ...formDetails.value, name: name.trim() }),
		}

		const save = () => context.emit('save', formDetails.value)
		const close = () => context.emit('close')

		const updateStatus = () => {
			if (!isStatusUpdateDisabled.value) {
				if (isDraft.value) {
					update.status(FORM_STATUS.PUBLISHED)
				} else {
					update.status(FORM_STATUS.DRAFT)
				}
			}
		}

		watch(formDetails, validateDetails, { immediate: true })
		watch(
			() => props.details,
			(details) => {
				formDetails.value = { ...details } as FormDetails
			}
		)

		return {
			isValid,
			formDetails,
			update,
			isDraft,
			isStatusUpdateDisabled,

			save,
			close,
			updateStatus,
		}
	},
})
