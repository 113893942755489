<template>
	<div
		class="drop-container"
		@drop.stop.prevent="listFileDrop"
		@dragleave.stop.prevent="selectSend('DRAG_LEAVE')"
		@dragover.stop.prevent="selectSend('DRAG_OVER')"
	>
		<slot></slot>
	</div>
</template>

<script lang="ts">
import { SetupContext, defineComponent } from 'vue'
import { useService } from '@xstate/vue'
import { dragAndDropService } from '../machine'
import { useFormSelection } from '../hooks'

export default defineComponent({
	name: 'DropContainer',
	setup(_, context: SetupContext) {
		const { send: selectSend } = useService(dragAndDropService)
		const { dropFiles } = useFormSelection(selectSend)

		const listFileDrop = (e: DragEvent) => {
			dropFiles(e)
			context.emit('listFileDrop', e)
		}

		return {
			selectSend,
			listFileDrop,
		}
	},
})
</script>

<style lang="scss" scoped>
.drop-container {
	overflow-y: scroll;
}
</style>
