
import { defineComponent, computed, ref, watch } from 'vue'

import { useAuthStore } from '@/modules/auth/store'
import { useFormsStore } from '@/modules/forms'
import { useFormMetadataList } from './hooks/form-metadata-list'
import { useFormListSelection } from './hooks/form-list-selection'
import { useFormImagePreviews } from './hooks/form-image-previews'

import paneConfig from './paneConfig'
import FormInformation from '@/components/FormInformation'
import FormListBody from '@/components/FormList/Body'
import FormListHeader from '@/components/FormList/Header'
import FormUploadFlow from '@/components/FormUploadFlow'
import Modal from '@/components/Modal'
import SearchBar from '@/components/SearchBar'
import Spacer from '@/components/Spacer'
import SplitPanes from '@/components/SplitPanes'
import ToolbarContainer from '@/components/Toolbar/Container'
import DropContainer from '@/components/FormUploadFlow/container'

import { constVoid } from 'fp-ts/lib/function'
import { FormMetadata } from '@/models/form/metadata'
import { useFlagsStore } from '@/modules/flags/store'

export default defineComponent({
	name: 'Files',
	layout: 'Default',
	components: {
		[DropContainer.name]: DropContainer,
		[FormInformation.name]: FormInformation,
		[FormListBody.name]: FormListBody,
		[FormListHeader.name]: FormListHeader,
		[FormUploadFlow.name]: FormUploadFlow,
		[Modal.name]: Modal,
		[SearchBar.name]: SearchBar,
		[Spacer.name]: Spacer,
		[SplitPanes.name]: SplitPanes,
		[ToolbarContainer.name]: ToolbarContainer,
	},
	setup() {
		const formStore = useFormsStore()
		const flagsStore = useFlagsStore()

		// Hooks
		const { isLoading, reloadFormMetadataList: reloadList } = useFormMetadataList()
		const { selectedForm, selectForm, openForm } = useFormListSelection()
		const { fetchPreviewImages } = useFormImagePreviews(selectedForm)

		const formMetadataList = computed<FormMetadata[]>(() => {
			return formStore.getters.sortedAndFilteredForms as FormMetadata[]
		})

		const ms18365DisableAddingNewForms = computed(
			() => flagsStore.getters['featureFlags']?.['ms18365DisableAddingNewForms']
		)

		// Form Upload Modal
		const upload = ref<InstanceType<typeof Modal> | null>(null)
		const showUploadModal = computed(() => upload.value?.showHandler ?? constVoid)

		// Form Preview Image Modal
		const selectedPage = ref<string | null>(null)
		const preview = ref<InstanceType<typeof Modal> | null>(null)

		const showPreviewModal = (event: MouseEvent & { srcElement: HTMLImageElement }) => {
			selectedPage.value = event.srcElement.src
			if (preview.value !== null) {
				preview.value.showHandler(event)
			}
		}

		// Forms Preview Panel
		const toggleRightPanel = ref<() => void>(() => ({}))

		const panes = ref<InstanceType<typeof SplitPanes> | null>(null)

		const isRightPanelVisible = computed(() => panes.value?.isRightEnabled ?? false)

		// Populate toggle functions on load
		watch([panes], ([panes]) => {
			if (panes !== null) {
				toggleRightPanel.value = panes.toggleRightPanel
			}
		})

		// Form List
		const columns: {
			label: string
			field: string
			sortable: boolean
		}[] = [
			{ label: 'Form Type', field: 'formType', sortable: false },
			{
				label: 'Form Name',
				field: 'name',
				sortable: true,
			},
			{ label: 'Status', field: 'formStatus', sortable: false },
			{
				label: 'Last Modified',
				field: 'lastModified',
				sortable: true,
			},
			{
				label: 'Updated By',
				field: 'updatedBy',
				sortable: true,
			},
			{
				label: 'Actions',
				field: 'actions',
				sortable: false,
			},
		]
		const searchValue = computed(() => formStore.state.searchText)
		const updateSearchText = (searchText: string) => formStore.commit('setSearchText', searchText)

		const authStore = useAuthStore()
		const logout = () => authStore.dispatch('logout')

		return {
			ms18365DisableAddingNewForms,

			// Template Refs
			panes,
			preview,
			upload,

			// Authentication
			logout,

			// Modal API
			showUploadModal,
			showPreviewModal,

			// Form List
			columns,
			formMetadataList,
			isLoading,
			searchValue,
			openForm,
			selectForm,
			reloadList,
			updateSearchText,

			// Preview Panel
			isRightPanelVisible,
			fetchPreviewImages,
			selectedPage,
			toggleRightPanel,
			paneConfig,
		}
	},
})
