import { Ref, computed } from 'vue'
import { PayloadSender, State } from 'xstate'
import { Event as SelectEvent } from './types'
import {
	Context as UploadContext,
	Event as UploadEvent,
	Schema as UploadSchema,
} from '@/modules/http/types'

import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { unwrapProgress } from '@/modules/http'

/**
 * Composition hook that enables selecting which form to upload via
 * drag-and-drop or selection with a file manager.
 */
export function useFormSelection(send: PayloadSender<SelectEvent>) {
	// Users may select files using a file manager...
	const input = document.createElement('input')
	const attachFiles = () => input.click()
	input.type = 'file'
	input.onchange = (e: Event) => {
		const files = pipe(
			(e.target as HTMLInputElement).files,
			O.fromNullable,
			O.fold(
				() => [],
				(f) => Array.from(f)
			)
		)

		send({
			type: 'ATTACH',
			files,
		})
	}

	// ...or they may drag and drop files over the drop zone.
	const dropFiles = (e: DragEvent) => {
		const files = pipe(
			e.dataTransfer,
			O.fromNullable,
			O.fold(
				() => [],
				(data) => Array.from(data.files)
			)
		)

		send({
			type: 'DROP',
			files,
		})
	}

	return {
		attachFiles,
		dropFiles,
	}
}

/**
 * Composition hook that enables uploading a form to the server.
 */
export function useFormUpload(
	state: Ref<State<UploadContext, UploadEvent, UploadSchema>>,
	send: PayloadSender<UploadEvent>
) {
	const uploadForm = (name: string, form: File, importFields: boolean) => {
		const formData = new FormData()
		formData.append('name', name)
		formData.append('form', form)

		send({
			type: 'SET_CONFIG',
			config: {
				data: formData,
				params: {
					auto_discover_fields: importFields,
				},
			},
		})

		send('DISPATCH')
	}

	const uploadProgress = computed(() => {
		return unwrapProgress(state.value.context.uploadProgress)
	})

	return {
		uploadForm,
		uploadProgress,
	}
}
