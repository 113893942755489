
import { computed, defineComponent, ref, SetupContext, watch } from 'vue'
import { FormMetadata } from '@/models/form/metadata'
import { useMyndmapApi } from '@/modules/api/hook'
import { useConfigStore } from '@/modules/mynd-config'

import Spacer from '@/components/Spacer'
import { toast } from '@/components/Toast'

import * as E from 'fp-ts/lib/Either'
import { UUID } from 'io-ts-types/lib/UUID'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { FORM_STATUS, FormDetails, FormType } from '@/models/form/form'
import { getApiErrorMessage } from '@/modules/api'
import { useFormMetadataList } from '@/pages/Files/hooks/form-metadata-list'
import Modal from '@/components/Modal/Modal.vue'
import { constVoid } from 'fp-ts/function'
import FormDetailEditor from '@/components/FormDetailEditor'

export default defineComponent({
	name: 'FormListBody',
	components: {
		Modal,
		Spacer,
		'font-awesome-icon': FontAwesomeIcon,
		FormDetailEditor,
	},
	props: {
		loading: Boolean,
		columns: {
			type: Array,
			default: () => [],
		},
		data: {
			type: Array,
			default: () => [],
		},
	},
	setup(props, context: SetupContext) {
		const selectedRow = ref<FormMetadata | null>(null)
		const configStore = useConfigStore()
		const config = computed(() => configStore.getters['getConfig'])
		const { reloadFormMetadataList: reloadList } = useFormMetadataList()
		const { updateFormDetails: updateFormDetailsRequest } = useMyndmapApi(config)

		const formId = ref<string>('')
		const saving = ref<boolean>(false)
		const editModal = ref<InstanceType<typeof Modal> | null>(null)

		const showEditModal = computed(() => editModal.value?.showHandler ?? constVoid)
		const hideEditModal = computed(() => editModal.value?.hideHandler ?? constVoid)

		const formTypeOptions: { id: string; value: string }[] = [
			{ id: FormType.PORTAL, value: 'Portal' },
			{ id: FormType.FAX, value: 'Fax' },
			{ id: FormType.MANUAL, value: 'Manual' },
		]

		const details = ref({
			name: selectedRow.value?.name,
			type: selectedRow.value?.formType,
			status: selectedRow.value?.formStatus,
		})

		const getFormTypeLabel = (type: string) =>
			formTypeOptions.find((formTypeOption) => (formTypeOption.id as string) === type)?.value

		const getFormStatusLabel = (status: string | null) => {
			let label = ''
			if (status) {
				label = status.toLowerCase()
				label = `${label[0].toUpperCase()}${label.slice(1)}`
			}
			return label
		}
		const reloadIcon = () => {
			reloadList()
		}

		//Selected Row
		watch([selectedRow], ([selectedRow]) => {
			context.emit('select', selectedRow)
			if (selectedRow?.id !== formId.value) {
				formId.value = ''
			}
		})

		const selectedFormId = (selectedFormId: UUID) => {
			const isRow = props.data.find((row: any) => row.id === selectedFormId)
			selectedRow.value = isRow as FormMetadata
		}

		const doubleClickRow = (id?: string) => {
			context.emit('open', id)
		}

		//Edit
		enum EditState {
			INACTIVE,
			SAVING,
		}

		const editState = ref<EditState>(EditState.INACTIVE)

		const copyForm = (row: any) => {
			const newName = window.prompt('Enter new form name', `${row.name} - COPY`)

			if (newName === null) {
				return
			} else if (newName.trim() === '') {
				toast(`Please enter a valid name`)
			} else {
				toast(`Copying form ${row.name} to ${newName}`)
				useMyndmapApi(config)
					.copyForm(row, newName.trim())()
					.then(
						E.fold(
							(error) => toast(getApiErrorMessage(error)),
							() => {
								context.emit('reloadList')
								toast(`Successfully copied ${row.name}`)
							}
						)
					)
			}
		}

		const deleteForm = (row: any) => {
			if (
				window.confirm(
					`Are you sure you want to delete: ${row.name}? This action cannot be undone.`
				)
			) {
				useMyndmapApi(config)
					.deleteForm(row.id)()
					.then(
						E.fold(
							(error) => toast(getApiErrorMessage(error)),
							() => {
								context.emit('reloadList')
								toast(`Successfully deleted ${row.name}`)
							}
						)
					)
			}
		}

		const updateFormDetails = (id: UUID, formDetails: FormDetails) => {
			saving.value = true
			editState.value = EditState.SAVING
			const detailsToSave: Record<string, any> = {}
			if (selectedRow.value?.['name'] !== formDetails.name) {
				detailsToSave['name'] = {
					newValue: formDetails.name,
					oldValue: selectedRow.value?.['name'],
				}
			}
			if (selectedRow.value?.['formType'] !== formDetails.type) {
				detailsToSave['form_type'] = {
					newValue: formDetails.type || FormType.MANUAL,
					oldValue: selectedRow.value?.['formType'] || FormType.MANUAL,
				}
			}
			if (selectedRow.value?.['formStatus'] !== formDetails.status) {
				detailsToSave['form_status'] = {
					newValue: formDetails.status || FORM_STATUS.DRAFT,
					oldValue: selectedRow.value?.['formStatus'] || FORM_STATUS.DRAFT,
				}
			}
			if (!Object.keys(detailsToSave).length) {
				hideEditModal?.value?.()
				saving.value = false
				editState.value = EditState.INACTIVE
			}

			if (Object.values(detailsToSave).some((detail) => !!Object.keys(detail).length)) {
				const keys = Object.keys(detailsToSave)

				if (selectedRow.value) {
					if (keys.includes('name')) {
						selectedRow.value.name = detailsToSave.name.newValue
					}
					if (keys.includes('form_type')) {
						selectedRow.value.formType = detailsToSave.form_type.newValue
					}
					if (keys.includes('form_status')) {
						selectedRow.value.formStatus = detailsToSave.form_status.newValue
					}
				}

				saveFormDetails(id, detailsToSave).then(
					E.fold(
						(error) => {
							editState.value = EditState.INACTIVE
							if (selectedRow.value) {
								if (keys.includes('name')) {
									selectedRow.value.name = detailsToSave.name.oldValue
								}
								if (keys.includes('form_type')) {
									selectedRow.value.formType = detailsToSave.form_type.oldValue
								}
								if (keys.includes('form_status')) {
									selectedRow.value.formStatus = detailsToSave.form_status.oldValue
								}
							}
							saving.value = false
							toast(getApiErrorMessage(error))
						},
						(_) => {
							editState.value = EditState.INACTIVE
							saving.value = false
							hideEditModal?.value?.()
						}
					)
				)
			}
		}

		const saveFormDetails = async (id: UUID, details: Record<string, any>) => {
			const executeRequest = updateFormDetailsRequest(id)(details)
			return await executeRequest()
		}

		watch(
			[
				() => selectedRow.value?.name,
				() => selectedRow.value?.formType,
				() => selectedRow.value?.formStatus,
			],
			([name, type, status]) => {
				details.value = { name, type, status }
			}
		)

		return {
			selectedRow,
			formId,
			editState,
			EditState,
			saving,
			editModal,
			showEditModal,
			hideEditModal,
			formTypeOptions,
			details,

			doubleClickRow,
			reloadList,
			reloadIcon,
			selectedFormId,
			copyForm,
			deleteForm,
			updateFormDetails,
			getFormTypeLabel,
			getFormStatusLabel,
		}
	},
})
