<template>
	<div class="spacer" :style="{ height: size + 'px', width: size + 'px' }"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'Spacer',
	props: {
		size: [Number, String],
	},
})
</script>

<style lang="scss" scoped>
.spacer {
	pointer-events: none;
	user-select: none;
}
</style>
