import { ref } from 'vue'
import { FormMetadata } from '@/models/form/metadata'
import { useRouter } from 'vue-router'

import * as O from 'fp-ts/lib/Option'

/**
 * Composition function that facilitates selecting and opening forms displayed
 * in the form list.
 */
export function useFormListSelection() {
	const selectedForm = ref<O.Option<FormMetadata>>(O.none)
	const router = useRouter()

	const selectForm = (form: FormMetadata) => {
		selectedForm.value = O.fromNullable(form)
	}

	const openForm = (form: FormMetadata) => {
		router.push(`/editor/${form.id}`)
	}

	return {
		selectedForm,
		selectForm,
		openForm,
	}
}
