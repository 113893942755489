
import { computed, defineComponent, onBeforeMount } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useFormsStore } from '@/modules/forms'
import { FormMetaDataKey } from '@/models/form/metadata'
import { SortOption } from '@/models/form/sorts'
import { nextSortOption } from '@/modules/forms'

const SORT_NUMERIC_ASC = 'sort-numeric-down'
const SORT_NUMERIC_DESC = 'sort-numeric-down-alt'
const SORT_ALPHA_ASC = 'sort-alpha-down'
const SORT_ALPHA_DESC = 'sort-alpha-down-alt'

export default defineComponent({
	name: 'FormListHeader',
	components: {
		'font-awesome-icon': FontAwesomeIcon,
	},
	props: {
		columns: {
			type: Array,
			default: () => [],
		},
	},
	setup() {
		const formsStore = useFormsStore()

		const toggledField = computed(() => formsStore.state.sortField)

		const showSort = (column: { sortable: boolean }) => column.sortable

		/**
		 * Toggle sort will toggle between ascending, descending, and disabled for a particular column
		 * NOTE: currently only one column can be sorted at a time
		 * @param columnName
		 */
		const toggleSort = (columnName: FormMetaDataKey) => {
			const sortOption =
				columnName === toggledField.value.name ? toggledField.value.option : SortOption.NONE

			formsStore.commit('setSortField', {
				name: columnName,
				option: nextSortOption(sortOption),
			})
		}

		const getIcon = (columnName: FormMetaDataKey) => {
			if (toggledField.value.name === columnName) {
				if (columnName === 'lastModified') {
					return toggledField.value.option === SortOption.DESCENDING
						? SORT_NUMERIC_DESC
						: SORT_NUMERIC_ASC
				}
				return toggledField.value.option === SortOption.DESCENDING
					? SORT_ALPHA_DESC
					: SORT_ALPHA_ASC
			}
			return columnName === 'lastModified' ? SORT_NUMERIC_ASC : SORT_ALPHA_ASC
		}

		onBeforeMount(() => {
			// Sets the sort to the name column on load
			formsStore.commit('setSortField', {
				name: 'name',
				option: SortOption.ASCENDING,
			})
		})

		return { toggledField, getIcon, showSort, toggleSort }
	},
})
