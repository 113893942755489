import { Machine, MachineConfig, assign } from 'xstate'

interface Context {
	count: number
	max: number
	period: number
}

interface Schema {
	states: {
		idle: {}
		running: {}
	}
}

type BeginEvent = {
	type: 'BEGIN'
	max: number
	period: number
}
type UpdateEvent = { type: 'UPDATE' }
type EndEvent = { type: 'END' }
type Event = BeginEvent | UpdateEvent | EndEvent

const config: MachineConfig<Context, Schema, Event> = {
	id: 'ProgressDotsMachine',
	initial: 'idle',
	context: {
		count: 0,
		max: 3,
		period: 1000,
	},
	states: {
		idle: {
			on: {
				BEGIN: {
					target: 'running',
					actions: [
						assign({
							max: (_, evt) => evt.max,
							period: (_, evt) => evt.period,
						}),
					],
				},
			},
			entry: assign({
				// eslint-disable-next-line
				count: (_) => 0,
			}),
		},
		running: {
			invoke: {
				id: 'update',
				src: (ctx) => (cb) => {
					const id = setTimeout(() => cb('UPDATE'), ctx.period)
					return () => clearTimeout(id)
				},
			},

			on: {
				END: 'idle',
				UPDATE: {
					target: 'running',
					actions: [
						assign({
							count: (ctx) => (ctx.count + 1) % (ctx.max + 1),
						}),
					],
				},
			},
		},
	},
}

export const progressDotsMachine = Machine<Context, Schema, Event>(config)
